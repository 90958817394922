<template>
	<div class="ew-container">
		<canvas v-bind:id="cameraCanvas" />

		<canvas ref="textCanvas" :id="textCanvasID"  />

		<transition name="fade">
			<div v-if="isScanning" class="scanning" >
				<img class="frame" src="/assets/images/frame_target.png" />

				<img class="icon" src="/assets/images/out_of_frame.png" />

				<p>{{locale.scan_instructions}}</p>
			</div>
		</transition>

		<transition name="fade">
			<ButtonComp v-if="!isScanning && showNextButton"
				@onButtonClicked="startExperience"
				:background="true">
				{{locale.start_button}}
			</ButtonComp>

			<ButtonComp v-if="showCTAButton"
				@onButtonClicked="goToPage"
				:background="true">
				{{locale.cta_button}}
			</ButtonComp>
			<!-- <button v-if="!isScanning && showNextButton" class="next-button" v-on:click="startExperience">{{locale.start_button}}</button>
			<button v-if="showCTAButton" class="next-button" v-on:click="goToPage">{{locale.cta_button}}</button> -->
		</transition>
	</div>
</template>

<script>

import MyEighthWall from '@/vendor/MyEighthWall';
import ButtonComp from './ButtonComp.vue';

import { mapState } from 'vuex'

const STATE_CAMERA_ACCESS = "camera";
const STATE_AR = 'ar';

export default {
	components: { ButtonComp },
	name: 'EighthWall',
	props: [
		'state',
		'isIOS',
		'lang'
	],
	data: function() {
		return {
			isLoaded: false,
			cameraCanvas: 'camerafeed',
			textCanvasID: 'textCanvas',
			isScanning: false,
			hasSeenImageTarget: false,
			showVideo: false,
			fontHeight: 40,
			textContext: null,
			showNextButton: false,
			showCTAButton: false,
			currentStage: 0
		}
	},
	computed: mapState( {
		buttonText() {
			return this.stageCTA[ this.currentStage ];
		},
		locale: state => state.locale,
	}),
	created: function() {

		// XR Extras - provides utilities like load screen, almost there, and error handling.
		// 	See github.com/8thwall/web/xrextras
		const xr8xtra = "//cdn.8thwall.com/web/xrextras/xrextras.js";

		// 8thWall Web - Replace the app key here with your own app key
		const xr8 = "//apps.8thwall.com/xrweb?appKey=sR5U4W12KJWueCv4Ae3dQCTkhDcqfHbxe44sWMYHTIAQxcU3SIf57c0npUT0Lcr0lV36q1";

		// Load the script dependencies
		Promise.all( [
			this.$loadScript( xr8 ),
			this.$loadScript( xr8xtra )
		] ).then( ( values ) => {
			console.log( "Scripts Loaded!" );
			window.addEventListener('xrloaded', this.onXRLoaded)
		}).catch( ( error ) => {
			console.error( "Could not load script: ", error );
		});
	},
	mounted: function() {
		this.setupTextContext();

	},
	methods: {
		onXRLoaded: function() {
			this.isLoaded = true;
			console.log( "Loaded XR8!", window.XR8 );

			this.init8Wall();
		},

		init8Wall: function() {

			// Init our 8th Wall manager class, and pass our callback functions to it.
			this.myEighthWall = new MyEighthWall( this.isIOS, this.cameraCanvas, this.textCanvasID, this.lang, {
				requesting: this.requestingVideo,
				hasStream: this.hasStream,
				hasVideo: this.hasVideo,
				failed: this.requestFailed,
				targetVisible: this.targetVisible,
				experienceReady: this.experienceReady,
				experienceCompleted: this.experienceCompleted,
				experienceReset: this.experienceReset,
				nextStage: this.nextStage,
				videoIntervalComplete: this.videoIntervalComplete
			} );

			this.myEighthWall.startXR8();
		},

		requestingVideo: function() {

			this.$emit( 'cameraStateChanged', 'requesting' );
		},

		hasStream: function() {

			this.$emit( 'cameraStateChanged', 'hasStream' );
		},

		hasVideo: function() {

			this.$emit( 'cameraStateChanged', 'hasVideo' );
			this.isScanning = true;
		},

		requestFailed: function() {

			this.$emit( 'cameraStateChanged', 'failed' );
		},

		targetVisible: function( visible ) {
			this.isScanning = !visible;

			if ( !this.hasSeenImageTarget ) {
				this.hasSeenImageTarget = true;
			}
		},

		setupTextContext() {
			this.textContext = this.$refs.textCanvas.getContext('2d');
		},

		doContextParams() {
			this.textContext.font = `${ this.fontHeight }px sans serif`
			this.textContext.fillStyle = "#0D2F76"
			this.textContext.textBaseline = "middle"
		},

		startExperience () {
			this.myEighthWall.start(this.isIOS);
			this.showNextButton = false;
		},

		experienceReady () {
			this.currentStage = 0;
			this.showNextButton = true;
		},

		experienceCompleted () {
			this.currentStage = 0;
			this.showCTAButton = true;
		},

		experienceReset () {
			this.currentStage = 0;
			this.showNextButton = false;
			this.showCTAButton = false;
		},

		goToPage () {
			window.open(this.$store.state.locale.page_link);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.ew-container {
	position: fixed;
	top: 0;
    right: 0;
    bottom: 0;
    left: 0;

	z-index: 2;

	text-align: center;
}

.ew-container #textCanvas {
	// background: orangered;
	visibility: hidden;
}

.ew-container .sound-container {
	position: absolute;
	top: 15px;
	right: 10px;

	display: flex;
	flex-direction: row;
	justify-content: space-evenly;

	padding: 10px;
	width: 120px;

	z-index: 50;

	// background: radial-gradient( #000000, transparent 70%)
}

.ew-container .sound-container img {
	width: 38px;
	height: 38px;

	z-index: 55;
}

.ew-container .scanning {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 100%;

	top: 50%;
	left: 50%;

	transform: translate( -50%, -50% );
}

.ew-container .scanning p {
	color: #0D2F76;
	width: 60%;
	margin: 0 auto;
}

.ew-container .scanning .icon {
	width: 53px;
	height: 53px;

	margin: 0 auto;
}

.ew-container .scanning .frame {
	width: 336px;

	margin: 0 auto;
}

// .ew-container .next-button {
//     position: fixed;
//     bottom: 50px;
//     left: 50%;
//     transform: translateX( -50% );

//     padding: 15px;

//     width: 200px;
//     height: 40px;

// 	font-family: 'Oswald';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     letter-spacing: 0.1em;
//     color: #ffffff;
// 	opacity: 0.7;

//     background: #118CA5;
//     border: solid 2px white;
// }

.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

</style>
