import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		locale: null
	},
	mutations: {
		setLocale (state, loc) {
			state.locale = loc;
		}
	},
	actions: {
	},
	modules: {
	},
});