<template>
	<div id="app">
		<EighthWall
			v-if="!isDesktop && (stateCameraAccess || stateAR)"
			v-on:cameraStateChanged="onCameraStateChanged"
			:state="state"
			:isIOS="isIOS"
			:lang="lang"
		/>
		<CameraAccess v-if="!isDesktop && stateCameraAccess" />
		<Desktop v-if="isDesktop" />
		<Landing
			v-if="stateHome"
			v-on:onContClicked="onContClicked"
			v-on:onEnClicked="onEnClicked"
			v-on:onFrClicked="onFrClicked"
			:lang="lang"
		/>
	</div>
</template>

<script>
import EighthWall from "./components/EighthWall.vue";
import Landing from "./components/Landing.vue";
import CameraAccess from "./components/CameraAccess.vue";
import Desktop from "./components/Desktop.vue";

import Bowser from "bowser";

import en from '@/locales/en.json';
import fr from '@/locales/fr.json';

const STATE_HOME = "home";
const STATE_CAMERA_ACCESS = "camera";
const STATE_AR = "ar";

export default {
	name: "App",
	components: {
		EighthWall,
		Landing,
		CameraAccess,
		Desktop,
	},
	data: function () {
		return {
			state: STATE_HOME,
			lang: "",
		};
	},
	computed: {
		stateHome: function () {
			return this.state === STATE_HOME;
		},
		stateCameraAccess: function () {
			return this.state === STATE_CAMERA_ACCESS;
		},
		stateAR: function () {
			return this.state === STATE_AR;
		},
		isDesktop: function () {
			return (
				Bowser.parse(window.navigator.userAgent).platform.type === "desktop"
			);
		},
		isIOS: function () {
			return Bowser.parse(window.navigator.userAgent).os.name === "iOS";
		}
	},
	created: function () {
		document.title = "We Answer the Call";
		this.setLocale("en");
	},
	mounted: function () {
		// this.setLocale("en");
	},
	methods: {
		setLocale: function(loc) {
			this.lang = loc;
			if(loc == "en")
				this.$store.commit('setLocale', en);
			else
				this.$store.commit('setLocale', fr);
		},
		onEnClicked: function () {
			this.setLocale("en");
		},
		onFrClicked: function () {
			this.setLocale("fr");
		},
		onContClicked: function () {
			this.state = STATE_CAMERA_ACCESS;
		},
		onCameraStateChanged: function (state) {
			if (state === "hasVideo") {

				this.state = STATE_AR;
			}
		},
	},
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Oswald:wght@400;500&display=swap');

@font-face {

	font-family: 'Open Sans', sans-serif;
	font-family: 'Oswald', sans-serif;

	font-family: "Lota Grotesque";
	src: url("./assets/fonts/LotaGrotesque-Bold.woff2") format("woff2"),
		url("./assets/fonts/LotaGrotesque-Bold.woff") format("woff"),
		url("./assets/fonts/LotaGrotesque-Bold.otf") format("opentype");
}

html,
body {
	position: fixed;
	width: 100%;
	height: -webkit-fill-available;
	background: #0D2F76;
}

body {
	position: fixed;
	width: 100%;
	min-height: 100%;
	min-height: -webkit-fill-available;
	background: #ffffff;
}

#app {
	font-family: "Lota Grotesque", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	// text-align: center;
	color: #A9E2F5;

	overflow: hidden;
	overscroll-behavior: none;

	z-index: 5;
}
</style>
