export const stages = [
    // 0 - Intro
    {
        type: "text",
        time: 0.3
    },
    // 1 - Line 1
    {
        type: "text",
        time: 5.04
    },
    // 2 - Line 2
    {
        type: "text",
        time: 8.05
    },
    // 3 - Line 3
    {
        type: "text",
        time: 10.7
    },
    // 4 - Line 4
    {
        type: "text",
        time: 12.9
    },
    // 5 - Line 5
    {
        type: "text",
        time: 16.4
    },
    // 6 - Nurses fade in
    {
        type: "nurses",
        time: 17
    },
    // 7 - Line 6
    {
        type: "text",
        time: 25.04
    },
    // 8 - Line 7
    {
        type: "text",
        time: 28.04
    }
]