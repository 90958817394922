import * as THREE from 'three';
import TWEEN, { Tween } from '@tweenjs/tween.js';
import Bowser from "bowser";

window.THREE = THREE;
const isIOS = Bowser.parse(window.navigator.userAgent).os.name === "iOS";


export const stage0 = ( mat1, mat2, nursesObj, motionLightRed, motionLightWhite, flameObj, flameLight ) => {

	const timedDelay = 1300;
	const sirenRepeats = 10;
	const alternateRepeats = 50;
	const sirenFreq = 200;

	const nursesRepeats = 3;
	const nursesColor = 0.7;

	// FLAME CONTROLS
	for( let i = 0; i < flameObj.length; i++ ) {

		let newPos;
		if( isIOS ) {
			newPos = new THREE.Vector3( flameLight.position.x, flameLight.position.y - 1.3, flameLight.position.z + 0);
		}
		else {
			newPos = new THREE.Vector3( flameLight.position.x, flameLight.position.y - 1.3, flameLight.position.z + 0);
		}
		
		let posTween = new TWEEN.Tween(flameLight)
			.to( { position: newPos }, 4000 )
			.easing( TWEEN.Easing.Sinusoidal.InOut )
			.yoyo( true )
			.repeat( 4 )
			.start();
		
		let intensityOffTween = new TWEEN.Tween(flameLight)
			.to( { intensity: 0 }, 1000 )

		posTween.chain(intensityOffTween);

		let intensityOnTween = new TWEEN.Tween(flameLight)
			.to( { intensity: 2.5 }, 1000 )
			.start();
	}
	
	
	

	// NURSES CONTROLS
	for ( let i = 0; i < nursesObj.length; i++ ) {
		let colorTween = new TWEEN.Tween(nursesObj[i].material.color)
			.to( { r: nursesColor, g: nursesColor, b: nursesColor }, 1300 )
			.easing( TWEEN.Easing.Quadratic.Out )
			.delay( timedDelay + ( i * 300 ) )
			.yoyo( true )
			.repeat( nursesRepeats )
			.start();
	
		// let zScale = 1.4 - (i * 0.1);
		let scale = { x:1, y:1, z: ( 1.4 - (i * 0.1) ) };
		
		let scaleTween = new TWEEN.Tween(nursesObj[i].scale)
			.to( scale, 500 )
			.easing( TWEEN.Easing.Quadratic.Out )
			.delay( timedDelay + ( i * 300 ) )
			.start();
	}


	// RED LIGHT CONTROLS
	let redTweenIntensity = new TWEEN.Tween(motionLightRed)
		.to( { intensity: 5 }, sirenFreq )
		.easing( TWEEN.Easing.Quadratic.Out )
		.delay( 200 )

	let redTweenPulse = new TWEEN.Tween(motionLightRed)
		.to( { intensity: 1 }, sirenFreq )
		.easing( TWEEN.Easing.Quadratic.Out )
		.yoyo( true )
		.repeat( sirenRepeats )

	let redTweenAlt = new TWEEN.Tween(motionLightRed)
		.to( { intensity: 2 }, sirenFreq )
		.easing( TWEEN.Easing.Quadratic.Out )
		.yoyo( true )
		.repeat( alternateRepeats )

	let redTweenOut = new TWEEN.Tween(motionLightRed)
		.to({intensity: 0}, 700)
		.easing( TWEEN.Easing.Quadratic.Out )
		
	redTweenAlt.chain(redTweenOut);
	redTweenPulse.chain( redTweenAlt );
	redTweenIntensity.chain( redTweenPulse )
		.start();



	// WHITE LIGHT CONTROLS
	let whiteTweenIntensity = new TWEEN.Tween(motionLightWhite)
		.to({intensity: 4}, sirenFreq)
		.easing( TWEEN.Easing.Quadratic.Out )
		.delay( 0 )

	let whiteTweenPulse = new TWEEN.Tween(motionLightWhite)
		.to( { intensity: 1 }, sirenFreq )
		.easing( TWEEN.Easing.Quadratic.Out )
		.yoyo( true )
		.repeat( sirenRepeats )

	let whiteTweenAlt = new TWEEN.Tween(motionLightWhite)
		.to( { intensity: 2 }, sirenFreq )
		.easing( TWEEN.Easing.Quadratic.Out )
		.yoyo( true )
		.repeat( alternateRepeats )

	let whiteTweenOut = new TWEEN.Tween(motionLightWhite)
		.to({intensity: 0}, 700)
		.easing( TWEEN.Easing.Quadratic.Out )
		
	whiteTweenAlt.chain(whiteTweenOut);
	whiteTweenPulse.chain( whiteTweenAlt );
	whiteTweenIntensity.chain( whiteTweenPulse )
		.start();


	// Initialize Text
	let tweenOut = new TWEEN.Tween( mat1 )
		.to( { opacity: 1 }, 0 )
		.start();

	let tweenOut2 = new TWEEN.Tween( mat2 )
		.to( { opacity: 0.4 }, 0 )
		.start();
};

export const stage1 = ( mat1, mat2 ) => {

	textTransition( mat1, mat2 );

}

export const stage2 = ( mat1, mat2 ) => {

	textTransition( mat1, mat2 );

}

export const stage3 = ( mat1, mat2 ) => {

	textTransition( mat1, mat2, true, 400, 400 );

}

export const stage4 = ( mat1, mat2 ) => {

	textTransition( mat1, mat2, true, 600, 400 );

}

export const stage5 = ( mat1, mat2 ) => {

	textTransition( mat1, mat2 );

}

export const stage6 = ( nursesObj ) => {

	const nursesColor = 1;

	// Fade in the nurses for the 4 sentences
	for ( let i = 0; i < nursesObj.length; i++ ) {
		let colorTween = new TWEEN.Tween(nursesObj[i].material.color)
			.to( { r: nursesColor, g: nursesColor, b: nursesColor }, 500 )
			.easing( TWEEN.Easing.Quadratic.Out )
			.delay( ( i * 300 ) )
			.start();
	}

}

export const stage7 = ( mat1, mat2 ) => {

	textTransition( mat1, mat2, true, 400, 400 );

}

export const stage8 = ( mat1, mat2 ) => {

	textTransition( mat1, mat2, false );

}

const textTransition = ( mat1, mat2, fadeIn = true, delay = 1200, fadeout = 400 ) => {
	const copyOpacity = 1;
	const dropShadowOpacity = 0.4;

	// FADE OUT
	let tweenOut = new TWEEN.Tween( mat1 )
		.to( { opacity: 0 },fadeout )
		.easing( TWEEN.Easing.Quadratic.Out )
		.start();

	let tweenOut2 = new TWEEN.Tween( mat2 )
		.to( { opacity: 0 }, fadeout )
		.easing( TWEEN.Easing.Quadratic.Out )
		.start();


	if( fadeIn ) {
		// FADE IN
		let tweenIn = new TWEEN.Tween( mat1 )
			.to( { opacity: copyOpacity }, 0 )
			.easing( TWEEN.Easing.Quadratic.Out )
			.delay( delay )
			.start();

		let tweenIn2 = new TWEEN.Tween( mat2 )
			.to( { opacity: dropShadowOpacity },0 )
			.easing( TWEEN.Easing.Quadratic.Out )
			.delay( delay )
			.start();	
	}
	
}