import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

window.THREE = THREE;

import SpriteSheetTexture from './TextureAnimator';

import { stages } from './config';
import * as Sequences from './Sequences';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';

import videoURL from '../assets/videos/Teck_Copper_Hospital_Installation_Poster.mp4'
import { LightProbe } from 'three';

var textureLoader = new THREE.TextureLoader();
let sceneCam;
let threeScene;

let copyAsset;
let muralAsset;
let lightAnimAsset;
let audioAsset;
let copyTexAsset;
let muralTexAsset;
let flameAsset;

const useLocal = false;

if( useLocal ) {
	copyAsset = '/assets/models/FullTextAnimation_031.fbx';
	muralAsset = '/assets/models/Mural_014.fbx';
	lightAnimAsset = '/assets/models/LightNull_09.fbx';
	audioAsset = '/assets/audio/TheCNAMantra_EDIT_FULL_003.mp3';
	copyTexAsset = '/assets/models/textures/copyTexture.png';
	muralTexAsset = '/assets/models/textures/CNAMural.jpg';
	flameAsset = '/assets/models/CNA_Logo_004.fbx';
}
else {
	copyAsset = 'https://ventureplay-cna-mural-ar.s3.us-west-2.amazonaws.com/FullTextAnimation_031.fbx';
	muralAsset = 'https://ventureplay-cna-mural-ar.s3.us-west-2.amazonaws.com/Mural_014.fbx';
	lightAnimAsset = 'https://ventureplay-cna-mural-ar.s3.us-west-2.amazonaws.com/LightNull_09.fbx';
	audioAsset = 'https://ventureplay-cna-mural-ar.s3.us-west-2.amazonaws.com/TheCNAMantra_EDIT_FULL_003.mp3';
	copyTexAsset = 'https://ventureplay-cna-mural-ar.s3.us-west-2.amazonaws.com/copyTexture.png';
	muralTexAsset = 'https://ventureplay-cna-mural-ar.s3.us-west-2.amazonaws.com/CNAMural.jpg';
	flameAsset = 'https://ventureplay-cna-mural-ar.s3.us-west-2.amazonaws.com/CNA_Logo_004.fbx';
}





export default class MyEighthWall {

	constructor( iOS, cID, txtCID, lang, callbacks ) {
		this.isIOS = iOS;
		this.canvasID = cID;
		this.textCanvasID = txtCID;
		this.lang = lang;
		this.callbacks = callbacks;
		this.currentStage = -1;

		this.isVisible = false;
		this.canStart = false;
		this.totalLoaded = 0;

		this.stages = stages;
	}

	restart = () => {
		this.currentStage = 0;

		for( let i=0; i < this.stages.length; i++ ) {
			console.log("");
		}
	}

	start = (iOS) => {
		this.currentStage = 0;
		this.canStart = true;
		if( iOS ) {
			// console.log("IS IOS");
			this.audio.play();
			// this.audio.pause();
		}
	}

	imageTargetPipelineModule = () => {

		const clock = new THREE.Clock();
		let tweenTimer = 0;

		this.audio = null;

		let flameLight;
		let flameLightParent;
		let motionLightRed;
		let motionLightWhite;
		let copyObj;
		let muralObj;
		let nursesObj = [];
		let lightObj;
		let flameObj = [];
		let flameMeshes;
		const dummy = new THREE.Object3D();

		let textMixer;
		let textAnimAction;

		let lightMixer;
		let lightAnimAction;

		let xpRun = false;
		let onInit = true;
		let filesLoaded = [false, false, false, false];

		let dropShadowMat;
		let copyMat;

		let lightBulb;
		let lightBulb2;
	
		// Populates some object into an XR scene and sets the initial camera position. The scene and
		// camera come from xr3js, and are only available in the camera loop lifecycle onStart() or later.
		const initXrScene = ({ scene, camera }) => {

			sceneCam = camera;
			threeScene = scene;

			this.audio = new Audio(audioAsset);
			this.audio.crossOrigin = 'anonymous';
			this.audio.loop = false;

			var ratios = {
				x: 1.015,
				y: 0.7293
			}
	
			// Add soft white light to the scene.
			// This light cannot be used to cast shadows as it does not have a direction.
			scene.add(new THREE.AmbientLight(0x404040, 5));
			
			flameLight = new THREE.PointLight(0xffffff, 0, 1.0);
			flameLightParent = new THREE.Object3D();

			scene.add(flameLightParent);
			scene.add(flameLight);

			flameLightParent.visible = true;
			flameLight.parent = flameLightParent;
			flameLight.position.set( 0, 0, 0 );
			


			motionLightRed = new THREE.PointLight(0xff0000, 0, 2.0);
			motionLightRed.position.set(0, 0, 0);
			motionLightRed.castShadow = true;
			scene.add(motionLightRed);

			motionLightWhite = new THREE.PointLight(0xffffff, 0, 1.0);
			motionLightWhite.position.set(0, 0, 0);
			motionLightWhite.castShadow = true;
			scene.add(motionLightWhite);


			var copyTexture = textureLoader.load(copyTexAsset);
			copyTexture.crossOrigin = 'anonymous';
			var muralTexture = textureLoader.load(muralTexAsset);
			muralTexture.crossOrigin = 'anonymous';

			

			const loader = new FBXLoader();

			loader.load( muralAsset, function ( object ) {			

				object.crossOrigin = 'anonymous';

				console.log("MURAL: ", object);

				for ( let i = 0; i < object.children.length; i++) {

					let nMat = new THREE.MeshPhongMaterial( {
						color: 0xffffff,
						map: muralTexture,
						depthTest: false
					} );

					object.children[i].castShadow = true;
					object.children[i].receiveShadow = true;
					object.children[i].material = nMat;

					nursesObj.push( object.children[i] );
				}

				muralObj = object;
				muralObj.renderOrder = 0;
				muralObj.visible = false;

				scene.add( muralObj );

				filesLoaded[0] = true;
			} );

			loader.load( copyAsset, function ( object ) {			

				object.crossOrigin = 'anonymous';

				console.log( "COPY ASSET: ", object );

				dropShadowMat = new THREE.MeshBasicMaterial( {
					color: 0xffffff,
					opacity: 0,
					transparent: true,
					map: copyTexture,
					depthTest: true
				} );

				copyMat = new THREE.MeshBasicMaterial( {
					color: 0xffffff,
					opacity: 0,					
					transparent: true,
					depthTest: true
				} );

				for ( let i = 0; i < object.children.length; i++ ) {
					object.children[i].material[0] = dropShadowMat;
					object.children[i].material[1] = copyMat;
				}

				copyObj = object;
				copyObj.renderOrder = 10;

				copyObj.visible = false;

				scene.add( copyObj );

				textMixer = new THREE.AnimationMixer( copyObj );

				textAnimAction = textMixer.clipAction( copyObj.animations[ 0 ] );
				textAnimAction.setLoop( THREE.LoopOnce );

				filesLoaded[1] = true;
			} );

			loader.load( lightAnimAsset, function ( object ) {
				
				object.crossOrigin = 'anonymous';

				// console.log("LIGHTS: ", object);	

				lightObj = object;
				lightObj.visible = false;

				scene.add( object );

				lightMixer = new THREE.AnimationMixer( object );

				lightAnimAction = lightMixer.clipAction( object.animations[ 0 ] );
				lightAnimAction.setLoop( THREE.LoopOnce );

				filesLoaded[2] = true;
			} );

			loader.load( flameAsset, function ( object ) {		
				
				console.log("FLAME: ", object);

				object.crossOrigin = 'anonymous';

				for( let i = 0; i < 3; i++ ) {
					let nMat = new THREE.MeshPhongMaterial( {
						color: 0x000000,
						transparent: true,
						opacity: 0.2,
						depthTest: false,
						specular: 0xffffff,
						blending: THREE.AdditiveBlending
					} );

					let mesh = new THREE.Mesh( object.children[0].geometry, nMat );
					mesh.renderOrder = 5;

					flameObj.push( mesh );

					scene.add( mesh );
				}

				filesLoaded[3] = true;
			} );
	
			// Set the initial camera position relative to the scene we just laid out. This must be at a
			// height greater than y=0.
			camera.position.set(0, 3, 0)
		}
	
		// Places content over image target
		const showTarget = ({ detail }) => {
			// When the image target named 'video-target' is detected, play video.
			// This string must match the name of the image target uploaded to 8th Wall.

			if ( filesLoaded[0] && filesLoaded[1] && filesLoaded[2] && filesLoaded[3]) {
				if ( detail.name === 'MuralTarget' || detail.name === 'MuralTargetV2' ) {
					this.isVisible = true;

					if( copyObj ) {
						const copyScale = 1;
						copyObj.quaternion.copy(detail.rotation);
						copyObj.scale.set(detail.scale/copyScale, detail.scale/copyScale, detail.scale/copyScale);
						copyObj.position.copy(detail.position);
						
						copyObj.visible = true;
					}
					if( muralObj ) {
						const muralScale = 1;
						muralObj.quaternion.copy(detail.rotation);
						muralObj.scale.set(detail.scale/muralScale, detail.scale/muralScale, detail.scale/muralScale);
						muralObj.position.copy(detail.position);
						
						muralObj.visible = true;
					}

					if( flameLightParent ) {
						let pos;
						if( this.isIOS ) {
							pos = new THREE.Vector3( detail.position.x, detail.position.y + 1.0, detail.position.z - 1);
						}
						else {
							pos = new THREE.Vector3( detail.position.x, detail.position.y + 1.5, detail.position.z + 1.0);
						}
						
						flameLightParent.quaternion.copy(detail.rotation);
						flameLightParent.scale.set(detail.scale, detail.scale, detail.scale);
						flameLightParent.position.copy(pos);
						
						
						flameLight.scale.set(detail.scale, detail.scale, detail.scale);
						flameLight.quaternion.copy(detail.rotation);
					}

					let flamePos = [];
					flamePos[0] = { x:detail.position.x - 0.2, y:detail.position.y + 0.6, z:detail.position.z + 0.2 };
					flamePos[1] = { x:detail.position.x - 0.05, y:detail.position.y + 0.1, z:detail.position.z + 0.3};
					flamePos[2] = { x:detail.position.x + 0.1, y:detail.position.y - 0.3, z:detail.position.z + 0.4 };
					if( this.isIOS ) {
						flamePos[0] = { x:detail.position.x - 0.2, y:detail.position.y + 0.35, z:detail.position.z - 0.6 };
						flamePos[1] = { x:detail.position.x - 0.05, y:detail.position.y + 0.3, z:detail.position.z - 0.1};
						flamePos[2] = { x:detail.position.x + 0.1, y:detail.position.y + 0.4, z:detail.position.z + 0.3 };
					}

					let flameScale = [0.4, 1, 0.6];
					
					for( let i = 0; i < flameObj.length; i++ ) {
						if( flameObj[i] ) {
							flameObj[i].quaternion.copy(detail.rotation);
							flameObj[i].scale.set(detail.scale * flameScale[i], detail.scale * flameScale[i], 0.1);
							flameObj[i].position.copy(flamePos[i]);
							
							flameObj[i].visible = true;	
						}	
					}
					
					if( onInit ) {

						let lightPos = { x:detail.position.x, y:detail.position.y, z:detail.position.z };
						
						if( motionLightRed && motionLightWhite && lightObj ) {
							lightObj.position.copy(lightPos)
							motionLightRed.position.copy(lightObj.position);
							motionLightWhite.position.copy(lightObj.position);

							// ********************** TEMP SHIT
							var geometry = new THREE.SphereGeometry(0.1, 10, 10);
							var material = new THREE.MeshPhongMaterial({
								color: 0xff0000,
								transparent: true,
								opacity: 1,
								side: THREE.DoubleSide,
								flatShading: false,
							});

							lightBulb = new THREE.Mesh( geometry, material );
							lightBulb.position.copy( lightObj.children[0].position );
							lightBulb.renderOrder = 0;
							// threeScene.add( lightBulb );
							lightBulb.visible = false;

							lightBulb2 = new THREE.Mesh( geometry, material );
							lightBulb2.position.copy( lightObj.children[1].position );
							lightBulb2.renderOrder = 0;
							// threeScene.add( lightBulb2 );
							lightBulb2.visible = false;
						}
					}

					if( motionLightRed && motionLightWhite) {
						const lightScale = 1;
						motionLightRed.scale.set(detail.scale/lightScale, detail.scale/lightScale, detail.scale/lightScale);
						motionLightRed.quaternion.copy(detail.rotation);

						motionLightWhite.scale.set(detail.scale/lightScale, detail.scale/lightScale, detail.scale/lightScale);
						motionLightWhite.quaternion.copy(detail.rotation);

						if( lightObj ) {
							const lightScale = 1;
							lightObj.quaternion.copy(detail.rotation);
							
							lightObj.scale.set(detail.scale/lightScale, detail.scale/lightScale, detail.scale/lightScale);
							let worldPos;
							if( this.isIOS ) {
								worldPos = new THREE.Vector3( detail.position.x, detail.position.y + 0.3, detail.position.z - 1.2);
							}
							else {
								worldPos = new THREE.Vector3( detail.position.x, detail.position.y + 1.2, detail.position.z + 0.3 );
							}
							
							lightObj.position.copy(worldPos);
							lightObj.children[0].quaternion.copy(detail.rotation);

							let tempPos = new THREE.Vector3();
							lightObj.children[1].getWorldPosition(tempPos);
							motionLightRed.position.copy( tempPos );

							let tempPos2 = new THREE.Vector3();
							lightObj.children[0].getWorldPosition(tempPos2);
							motionLightWhite.position.copy( tempPos2 );

							if(lightBulb && lightBulb2) {
								lightBulb.visible = true;
								lightBulb.position.copy( tempPos );
								// let pos = new THREE.Vector3();
								// flameLight.getWorldPosition(pos);
								// lightBulb.position.copy( pos );

								lightBulb2.visible = true;
								lightBulb2.position.copy( tempPos2 );
							} 
							
						}
						
						motionLightRed.visible = true;
						motionLightWhite.visible = true;
					}

					if( !xpRun && this.canStart )  {
						if( textAnimAction && lightAnimAction ) {
							clock.start();

							textAnimAction.paused = false;
							textAnimAction.play();

							lightAnimAction.paused = false;
							lightAnimAction.play();

							this.audio.play();

							xpRun = true;
						}
					}

					if( onInit) {
						for ( let i = 0; i < nursesObj.length; i++ ) {
							let tween = new TWEEN.Tween(nursesObj[i].material.color)
								.to({r: 0.3, g: 0.3, b: 0.3},500)
								.easing(TWEEN.Easing.Quadratic.In)
								.onComplete((obj) => { 
									this.callbacks.experienceReady();
								})
								.start();
						}
						onInit = false; 
					}

					this.callbacks.targetVisible( true );
					
				}
			}
		}
	
		// Hides the image frame when the target is no longer detected.
		const hideTarget = ({ detail }) => {
			if ( detail.name === 'MuralTarget' || detail.name === 'MuralTargetV2' ) {
				this.isVisible = false;

				if( xpRun && textAnimAction && lightAnimAction ) { 
					clock.running = false;
					
					textAnimAction.paused = true;

					lightAnimAction.paused = true;

					this.audio.pause();

					xpRun = false; 
				}

				// if( flameLight ) {
				// 	flameLight.intensity = 0;
				// }

				if( copyObj && muralObj ) {
					copyObj.visible = false;
					muralObj.visible = false;
				}

				for( let i = 0; i < flameObj.length; i++ ) {
					if( flameObj[i] ) { 
						flameObj[i].visible = false;
					} 
				}

				this.callbacks.targetVisible( false );
			}
		}
	
		// Grab a handle to the threejs scene and set the camera position on pipeline startup.
		const onStart = ({ canvas }) => {
			const { scene, camera } = XR8.Threejs.xrScene()  // Get the 3js scene from XR
	
			initXrScene({ scene, camera })  // Add content to the scene and set starting camera position.
	
			// prevent scroll/pinch gestures on canvas
			canvas.addEventListener('touchmove', (event) => {
				event.preventDefault()
			})
	
			// Sync the xr controller's 6DoF position and camera paremeters with our scene.
			XR8.XrController.updateCameraProjectionMatrix({
				origin: camera.position,
				facing: camera.quaternion,
			})
		}

		// onUpdate is called once per camera loop prior to render. Any 3js geometry scene would
		// typically happen here.
		const onUpdate = ( event ) => {

			// this.sphere.position.set(sceneCam.position.x, sceneCam.position.y, sceneCam.position.z);

			if ( this.isVisible ) {

				const delta = clock.getDelta();

				// tweenTimer += ( delta * 1000);
				TWEEN.update(  );

				// console.log( tweenTimer );
				if ( xpRun ) {
					if ( textMixer ) textMixer.update( delta );
					if ( lightMixer ) lightMixer.update( delta );
					// if ( muralMixer ) muralMixer.update( delta );
				}

				if ( this.canStart ) {
					switch ( this.currentStage ) {
						case 0:
							if ( textAnimAction.time >= this.stages[ this.currentStage ].time) {
								Sequences.stage0( copyMat, dropShadowMat, nursesObj, motionLightRed, motionLightWhite, flameObj, flameLight );
								this.currentStage++;
							}
							break;
						case 1:
							if ( textAnimAction.time >= this.stages[ this.currentStage ].time) {
								Sequences.stage1(copyMat, dropShadowMat );
								this.currentStage++;
							}
							break;
						case 2:
							if ( textAnimAction.time >= this.stages[ this.currentStage ].time) {
								Sequences.stage2(copyMat, dropShadowMat );
								this.currentStage++;
							}
							break;
						case 3:
							if ( textAnimAction.time >= this.stages[ this.currentStage ].time) {
								Sequences.stage3(copyMat, dropShadowMat );
								this.currentStage++;
							}
							break;
						case 4:
							if ( textAnimAction.time >= this.stages[ this.currentStage ].time) {
								Sequences.stage4(copyMat, dropShadowMat );
								this.currentStage++;
							}
							break;
						case 5:
							if ( textAnimAction.time >= this.stages[ this.currentStage ].time) {
								Sequences.stage5(copyMat, dropShadowMat );
								this.currentStage++;
							}
							break;
						case 6:
							if ( textAnimAction.time >= this.stages[ this.currentStage ].time) {
								Sequences.stage6( nursesObj );
								this.currentStage++;
							}
							break;
						case 7:
							if ( textAnimAction.time >= this.stages[ this.currentStage ].time) {
								Sequences.stage7(copyMat, dropShadowMat );
								this.currentStage++;
							}
							break;
						case 8:
							if ( textAnimAction.time >= this.stages[ this.currentStage ].time) {
								Sequences.stage8(copyMat, dropShadowMat );
								this.currentStage++;
							}
							break;
						case 9:
							if ( textAnimAction.time >= textAnimAction.getClip().duration ) {
								xpRun = false;
								this.canStart = false;
								this.callbacks.experienceCompleted();
							}
							break;
						default:
							break;
					}
				}
			}
			
			// this.prevFrame = Date.now();
		}
	
		return {
			// Camera pipeline modules need a name. It can be whatever you want but must be
			// unique within your app.
			name: 'ventureplay-cna-ar-mural',
	
			// onStart is called once when the camera feed begins. In this case, we need to wait for the
			// XR8.Threejs scene to be ready before we can access it to add content. It was created in
			// XR8.Threejs.pipelineModule()'s onStart method.
			onStart,

			onUpdate,
	
			// Listeners are called right after the processing stage that fired them. This guarantees that
			// updates can be applied at an appropriate synchronized point in the rendering cycle.
			listeners: [
				{ event: 'reality.imagefound', process: showTarget },
				{ event: 'reality.imageupdated', process: showTarget },
				{ event: 'reality.imagelost', process: hideTarget },
			],
		}
	}

	cameraPermissionModule = () => {

		const onCameraStatusChange = ({ status }) => {

			if (status == 'requesting') {
				// myApplication.showCameraPermissionsPrompt()
				this.callbacks.requesting();
			} else if (status == 'hasStream') {
				// myApplication.dismissCameraPermissionsPrompt()
				this.callbacks.hasStream();
			} else if (status == 'hasVideo') {
				// myApplication.startMainApplictation()
				this.callbacks.hasVideo();
			} else if (status == 'failed') {
				// myApplication.promptUserToChangeBrowserSettings()
				this.callbacks.failed();
			}
		}

		return {
			name: 'cna-mural-camera-startup',
			onCameraStatusChange,
		}

	}

	startXR8 = () => {
		// If your app only interacts with image targets and not the world, disabling world tracking can
		// improve speed.
		XR8.XrController.configure({ disableWorldTracking: true })
		XR8.addCameraPipelineModules([  // Add camera pipeline modules.
			// Existing pipeline modules.
			XR8.GlTextureRenderer.pipelineModule(),      // Draws the camera feed.
			XR8.Threejs.pipelineModule(),                // Creates a ThreeJS AR Scene.
			XR8.XrController.pipelineModule(),           // Enables SLAM tracking.
			XRExtras.AlmostThere.pipelineModule(),       // Detects unsupported browsers and gives hints.
			XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
			// XRExtras.Loading.pipelineModule(),           // Manages the loading screen on startup.
			XRExtras.RuntimeError.pipelineModule(),      // Shows an error image on runtime error.
			// Custom pipeline modules.
			this.imageTargetPipelineModule(),            // Shows the video when our image target is detected
			this.cameraPermissionModule(),
		])
	
		// Open the camera and start running the camera run loop.
		XR8.run({ canvas: document.getElementById( this.canvasID ) })
	}
}
