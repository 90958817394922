<template>
    <div class="camera-access-container">
        <div class="header-bar">
            <img class="logo-img" src="/assets/images/cna-logo.svg" />
        </div>

        <div class="instructions">
            <img class="logo-instructions" src="/assets/images/ar-icon.png" />
            <p >{{locale.camera_instructions}}</p>
            <img class="poweredby-img" src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg" />
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    components: {  },
    name: 'CameraAccess',
    props: [
        'isOOH'
    ],
	data: function() {
		return {
		}
	},
    computed: mapState( {
		locale: state => state.locale,
	}),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.camera-access-container {
    height: 100%;

    background: #118CA5;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    
}

.camera-access-container .header-bar {
    width: 100%;
    background: #ffffff;
}

.camera-access-container .header-bar .logo-img {
    position: relative;
    display: block;
	width: 161px;

    padding: 10px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.camera-access-container .instructions {
    position: fixed;
    bottom: 45px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.camera-access-container .instructions p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17.25px;

    margin: 10px 0 50px;
    text-align: center;
}

.camera-access-container .poweredby-img {
    width: 35vw;
    max-width: 125px;
}
</style>