<template>
    <div class="landing-container">
        <transition name="fade">
        <div class="side-bar">
            <div class="header-bar" v-if="!isDesktop">
                <img class="logo-img" src="/assets/images/cna-logo.svg" />
            </div>
            <div class="copy-container">
                <div v-if="!isDesktop">
                    <h1>{{locale.landing_header}}</h1>
                    <div class="divider"></div>
                    <div class="instructions">
                        <h5>{{locale.landing_subheader}}</h5>
                        <ol>
                            <li>{{locale.landing_instructions_1}}</li>
                            <li>{{locale.landing_instructions_2}}</li>
                            <li>{{locale.landing_instructions_3}}</li>
                        </ol>
                    </div>
                </div>
                <div v-if="isDesktop">
                    <h1 class="desktop-header">{{locale.landing_header_desktop}}</h1>
                    <div class="instructions">
                        <h5>{{locale.landing_subheader_desktop}}</h5>
                        <ol>
                            <li>{{locale.landing_instructions_1_desktop}}</li>
                            <li>{{locale.landing_instructions_2_desktop}}</li>
                            <li>{{locale.landing_instructions_3_desktop}}</li>
                        </ol>
                    </div>
                    <h1 class="desktop-header">{{getFrench().landing_header_desktop}}</h1>
                    <div class="instructions">
                        <h5>{{getFrench().landing_subheader_desktop}}</h5>
                        <ol>
                            <li>{{getFrench().landing_instructions_1_desktop}}</li>
                            <li>{{getFrench().landing_instructions_2_desktop}}</li>
                            <li>{{getFrench().landing_instructions_3_desktop}}</li>
                        </ol>
                    </div>
                </div>
                <div class="button-container-desktop" v-if="isDesktop">
                    <ButtonComp
                        @onButtonClicked="onContClicked"
                        :background="false">{{locale.continue_button}}/{{getFrench().continue_button}}
                    </ButtonComp>
                </div>
                <div class="button-container" v-if="!isDesktop">
                    <ButtonComp
                        @onButtonClicked="onContClicked"
                        :background="false">{{locale.continue_button}}
                    </ButtonComp>
                </div>
            </div>
            <img class="logo-img-desktop" v-if="isDesktop" src="/assets/images/cna-logo-desktop.svg" />
            <div class="language-buttons">
                <button class="en-button" :class="lang == 'en' ? 'selected' : ''" v-if="!isDesktop" v-on:click="onEnClicked">ENG</button>
                <button class="fr-button" :class="lang == 'fr' ? 'selected' : ''" v-if="!isDesktop" v-on:click="onFrClicked">FRN</button>
            </div>
        </div>
        </transition>
        <transition name="fade">
            <div class="desktop-cover" v-if="isDesktop">
            </div>
        </transition>
    </div>
</template>

<script>

// import davidImage from '@/assets/images/sk-david.png';

import Bowser from "bowser";
import { mapState } from 'vuex'
import ButtonComp from './ButtonComp.vue';
import fr from '@/locales/fr.json';

export default {
    components: { ButtonComp },
    name: 'Landing',
    props: [
        'lang'
    ],
	data: function() {
		return {
		}
    },
    computed: mapState( {
        isDesktop: function () {
			return (
				Bowser.parse(window.navigator.userAgent).platform.type === "desktop"
			);
		},
		locale: state => state.locale
	}),
    methods: {
        onContClicked() {
            this.$emit( 'onContClicked' )
        },
        onEnClicked() {
            this.$emit( 'onEnClicked' )
        },
        onFrClicked() {
            this.$emit( 'onFrClicked' )
        },
        getFrench() {
            return fr;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.landing-container {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
}

.desktop-cover {
    z-index: 35;

    position: relative;

    width: 100%;
    height: 100%;

    background: black;
    opacity: 0.5;
}

.side-bar {
    z-index: 35;

    position: relative;

    background: #118CA5;

    display: flex;
    flex-direction: column;
	align-items: left;
    justify-content: left;
	width: 100%;
    height: 100%;

    @media (min-width: 750px) { 
        width: 35%;
        max-width: 350px;
    }
}

.side-bar .header-bar {
    background: #ffffff;
}

.side-bar .header-bar .logo-img {
    position: relative;
    display: block;
	width: 161px;

    padding: 10px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.side-bar .copy-container {
	display: flex;
	flex-direction: column;
	align-items: left;
    justify-content: left;
	// width: 100%; 

    margin: 0px 40px;
}

.side-bar .copy-container .divider{
    margin: 20px 0px;

    height: 1px;
	width: 60px;

    background: #ffffff;
}

.side-bar .copy-container h1 {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    color: #ffffff;

    margin: 0;
	margin-top: 30px;
}

.side-bar .copy-container .desktop-header {
    font-size: 30px;
    margin-top: 30px;
}

.side-bar .copy-container .instructions {
    width: 100%;
}

.side-bar .copy-container .instructions h5 {
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 130%;
	margin: 0;
}

.side-bar .copy-container .instructions ol {

    // width: 90%;
    color: #ffffff;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18.7px;

    padding-inline-start: 10px;
    margin-top: 15px;
}

.side-bar .copy-container .button-container-desktop {
    margin-top: 30px;
    width: 100%;
    text-align: center;
}

.side-bar .copy-container .button-container {
    width: 100%;
    text-align: center;
}

.side-bar .language-buttons {

    // width: 100%;

    position: absolute;
    bottom: 30px;

    left: 50%;
    transform: translateX( -50% );
    
    align-self: center;
    background: none;
}

.side-bar .logo-img-desktop {

    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;

    display: block;
	width: auto;

    margin: 0 40px;
}

.side-bar .language-buttons .en-button {
    padding: 15px;

    width: 60px;
    height: 51px;

    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.1em;
    color: #ffffff;
    opacity: 0.5;

    border: none;
    background: none;
}

.side-bar .language-buttons .en-button.selected {
    opacity: 1.0;
}

.side-bar .language-buttons .fr-button {
    padding: 15px;

    width: 60px;
    height: 51px;

    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.1em;
    color: #ffffff;
    opacity: 0.5;

    border: none;
    background: none;
}

.side-bar .language-buttons .fr-button.selected {
    opacity: 1.0;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

</style>